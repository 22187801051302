import { render, staticRenderFns } from "./Valid.vue?vue&type=template&id=4c4a3cb6&scoped=true"
var script = {}
import style0 from "./Valid.vue?vue&type=style&index=0&id=4c4a3cb6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c4a3cb6",
  null
  
)

export default component.exports