<template>
    <div>
        <div v-if="type === 'job'">
            <inreview v-if="status === 0"></inreview>
            <live v-if="status === 1"></live>
            <rejected v-if="status === 2"></rejected>
            <expired v-if="status === 3"></expired>
            <close v-if="status === 4"></close>
        </div>
        <div v-if="type === 'cv'">
            <pending v-if="status_string === 'pending'"></pending>
            <done v-if="status_string === 'done'"></done>
        </div>
        <div v-if="type === 'instajob'">
            <rejected v-if="status_string === 'reject'"></rejected>
            <valid v-if="status_string === 'valid'"></valid>
            <invalid v-if="status_string === 'invalid'"></invalid>
        </div>
        <div v-if="type === 'liveStream'">
            <off v-if="status === 1" />
            <on v-else-if="status === 2" />
            <finished v-else-if="status === 3" />
        </div>
    </div>
</template>

<script>
import Live from '@/components/globals/label/Live';
import Rejected from '@/components/globals/label/Rejected';
import Pending from '@/components/globals/label/Pending';
import Done from '@/components/globals/label/Done';
import Valid from '@/components/globals/label/Valid';
import Inreview from '@/components/globals/label/Inreview';
import Invalid from '@/components/globals/label/Invalid';
import Expired from '@/components/globals/label/Expired';
import Close from '@/components/globals/label/Close';
import On from '@/components/globals/label/On';
import Off from '@/components/globals/label/Off';
import Finished from '@/components/globals/label/Finished';

export default {
    props: {
        status: {
            required: false,
            type: Number
        },
        status_string: {
            required: false,
            type: String
        },
        type: {
            required: true,
            type: String
        }
    },
    components: {
        Live,
        Rejected,
        Pending,
        Done,
        Valid,
        Inreview,
        Invalid,
        Expired,
        Close,
        On,
        Off,
        Finished
    }
};
</script>
