<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'title')" type="text" placeholder="Search">
                    </div>
                </div>
                <div>
                    <div class="uk-form-controls uk-width-small">
                        <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
                            <option :value="''">Select status</option>
                            <option :value="0">Inreview</option>
                            <option :value="1">Live</option>
                            <option :value="2">Rejected</option>
                            <option :value="3">Expired</option>
                        </select>
                    </div>
                </div>
                <div class="uk-width uk-margin-remove-top" style="padding-top: 10px">
                    <div class="uk-text-right">
                        <button class="uk-button uk-button-success" @click="createJob">Create Job</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
                    <thead>
                        <tr>
                            <th>Nama Bisnis</th>
                            <th>Posisi yang Dibuka</th>
                            <th>Lokasi Pekerjaan</th>
                            <th>Email</th>
                            <th>Nomor Telepon 1</th>
                            <th>Nomor Telepon 2</th>
                            <th>Sumber</th>
                            <th>Komentar</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="job_admins.docs.length>0">
                        <tr v-for="(job, i) in job_admins.docs" :key="i">
                            <td>{{job.name || '-'}}</td>
                            <td>{{job.title || '-'}}</td>
                            <td>{{job.address || '-'}}</td>
                            <td>{{job.email || '-'}}</td>
                            <td>{{job.phone_number || '-'}}</td>
                            <td>{{job.phone_number_second || '-'}}</td>
                            <td>{{job.type || '-'}}</td>
                            <td>{{job.comment || '-'}}</td>
                            <td>
                                <label-status :type="'job'" :status="job.status" />
                            </td>
                            <td>
                                <button
                                    style="width:100px; cursor:pointer; margin-bottom:5px; height:30px; background:#333747"
                                    class="uk-button-primary"
                                    v-clipboard:copy="job.id"
                                    v-clipboard:success="onCopy"
                                >Copy Id</button><br/>
                                <button style="width:100px; cursor:pointer; height:30px; background:#ffa51f" class="uk-button-primary" @click="updateJob(job.id)">Ubah</button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="11"/>
                </table>
            </div>
            <pagination
                :total-data="job_admins.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
    </div>
</template>

<script>
import formatter from "@/utils/formatter";
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LabelStatus from '@/components/globals/LabelStatus';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    //notificationDanger
} from '@/utils/notification';

export default {
    data() {
        return {
            meta: {
                status: '',
                limit: 100,
                page: 1
            }
        };
    },
    components: {
        Pagination,
        EmptyTable,
        LabelStatus
    },
    computed : {
        ...mapGetters({
            job_admins: 'job_admin/job_admins',
            job_datas: 'job_admin/job_datas',
        })
    },
    watch: {
        meta() {
            this.setJobMeta();
        },
    },
    mounted() {
        this.setJobMeta();
        this.getJobData();
    },
    methods: {
        ...mapActions({
            getJob: 'job_admin/getJob',
            getJobData: 'job_admin/getJobData'
        }),
        fullName(data) {
            return formatter.getFullname(data);
        },
        setJobMeta() {
            this.getJob(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
        createJob() {
            this.$router.push(`/admin/job-admin/create`);
        },
        updateJob(jobId) {
            this.$router.push(`/admin/job-admin/update/${jobId}`);
        },
    },
};
</script>
